import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import ServiceCard from "components/our-services/ServiceCard";
import { useNavigate } from "react-router-dom";
import { services } from "utils/constants";
import { routes } from "utils/routes";

const OurServices = () => {
  const navigate = useNavigate();
  const handleNavigate = (id: string) => () => {
    navigate(`${routes.ServiceDetail}${id}`);
  };
  return (
    <Box px={{ base: "0rem", md: "7rem" }} pb="4rem" w={"100%"}>
      <Box position={"relative"} w="100%" my="2rem">
        <Box
          w="100%"
          h="1rem"
          _before={{
            content: "''",
            display: "block",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "4rem",
            backgroundImage: "url('/img/dots-bg-repeat.png')",
            backgroundRepeat: "repeat-x",
            backgroundSize: "contain",
          }}
        ></Box>
      </Box>
      <Stack
        direction={{ md: "row", base: "column" }}
        spacing={"1rem"}
        justifyContent="left"
        alignItems={"left"}
        mb="4rem"
      >
        <Box w={{ md: "47%", base: "100%" }}>
          <Text
            variant={"header"}
            color="#122c6c"
            position={"relative"}
            _before={{
              content: "''",
              position: "absolute",
              left: "0",
              bottom: "0",
              top: "calc(100% + 10px)",
              height: "0.5rem",
              width: "50%",
              backgroundColor: "rgb(246, 142, 19) !important",
            }}
          >
            What We Can Do For You
          </Text>
        </Box>
      </Stack>

      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        columnGap={{ base: 4, sm: 4, md: 6 }}
        rowGap={{ base: 10, md: 12 }}
      >
        {services.map((el, index) => (
          <GridItem
            w="100%"
            key={"item" + String(index)}
            onClick={handleNavigate(el.id)}
            cursor="pointer"
          >
            <ServiceCard
              title={el.title}
              src={el.img}
              description={el.shortDescription}
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default OurServices;
