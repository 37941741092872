import {
  Menu,
  MenuButton,
  MenuList,
  MenuListProps,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface Props {
  name: string | React.ReactNode;
  value?: string;
  url?: string;
}

const MenuListItem: React.FC<MenuListProps & Props> = ({
  name,
  children,
  url,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Menu isOpen={isOpen}>
      <MenuButton
        mx={1}
        py={[1, 2, 2]}
        px={4}
        borderRadius={5}
        aria-label="Courses"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        fontWeight="bold"
        padding="0 1.5rem"
        fontSize="1.4rem"
        _hover={{
          color: "var(--orange-color)",
        }}
        display="flex"
        onClick={() => {
          if (url) navigate(url);
        }}
      >
        {name}
      </MenuButton>
      <MenuList
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        padding="2rem"
        borderRadius={"0"}
        minW="18rem"
      >
        {children}
      </MenuList>
    </Menu>
  );
};

export default MenuListItem;
