import { Divider, Flex } from "@chakra-ui/react";
import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import { useEffect, useRef, useState } from "react";
import { RiArrowUpSLine } from "react-icons/ri";
import { Outlet } from "react-router-dom";

const LayoutContainer = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isFixed, setFixed] = useState<boolean>(false);

  useEffect(() => {
    const current = contentRef?.current;
    const handleScroll = (e: any) => {
      if (e.target?.scrollTop > 100) {
        setFixed(true);
        return;
      }
      if (e.target?.scrollTop <= 100) {
        setFixed(false);
        return;
      }
    };
    current?.addEventListener("scroll", handleScroll);
    return () => {
      current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onScrollToTop = () => {
    const current = contentRef?.current;
    if (current)
      current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  return (
    <Flex
      h="100vh"
      overflow={"hidden"}
      overflowY="auto"
      flexDir={"column"}
      position="relative"
      // bgImage={"url('/img/background-hero.jpeg')"}
      // bgRepeat="no-repeat"
      // bgSize={"cover"}
      ref={contentRef}
      id="root-container"
    >
      <Header isFixed={isFixed} />
      <Flex flex={1} flexDir="column" position={"relative"} px="1.5rem">
        <Outlet />
      </Flex>
      <Divider />
      <Footer />
      {isFixed && (
        <Flex
          w="5rem"
          h="5rem"
          borderRadius={"50%"}
          bg="var(--orange-color)"
          color="white"
          justifyContent={"center"}
          alignItems="center"
          cursor={"pointer"}
          position="fixed"
          bottom={{ base: "5rem", md: "10rem" }}
          right={{ base: "2rem", md: "5rem" }}
          zIndex={1000}
          onClick={onScrollToTop}
        >
          <RiArrowUpSLine size={"3rem"} />
        </Flex>
      )}
    </Flex>
  );
};
export default LayoutContainer;
