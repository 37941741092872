export const linkStyle = {
  variants: {
    overlay: {
      fontWeight: "bold",
      padding: "0 1.5rem",
      fontSize: "1.4rem",
      color: "#252525",
      _hover: {
        textDecoration: "none",
        color: "#f68e13",
      },
    },
    linkDrawer: {
      w: "100%",
      fontWeight: "bold",
      padding: "1rem 0",
      opacity: 0.7,
      fontSize: "1.4rem",
      color: "#252525",
      _hover: {
        textDecoration: "none",
        color: "#f68e13",
      },
    },
    linkItem: {
      fontWeight: "normal",
      padding: "0.3rem 1rem 0.3rem 0",
      fontSize: "1.4rem",
      color: "#252525",
      w: "100%",
      _hover: {
        textDecoration: "none",
        color: "#f68e13",
      },
    },
    blogLink: {
      color: "var(--blue-color)",
      _hover: {
        textDecoration: "none",
        color: "#044CD0 !important",
      },
    },
    footerLink: {
      color: "var(--gray-color)",
      _hover: {
        textDecoration: "none",
        color: "var(--orange-color)",
      },
    },
    hoverLink: {
      fontSize: {
        base: "1.3rem",
        md: "1.4rem",
      },
      color: "var(--blue-color)",
    },
  },
};
