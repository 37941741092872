import { Flex, StackProps, Text } from "@chakra-ui/react";
import React from "react";

const CellFooter = ({
  title,
  content,
  ...rest
}: {
  title: string;
  content: React.ReactNode;
} & StackProps) => {
  return (
    <Flex flexDirection={"column"} w={"100%"} {...rest}>
      <Text
        as="h3"
        _before={
          title
            ? {
                content: "''",
                position: "absolute",
                left: "0",
                bottom: "0",
                height: "2px",
                width: "70px",
                backgroundColor: "rgb(246, 142, 19) !important",
              }
            : {}
        }
        fontSize="1.9rem"
        fontWeight={"bold"}
        pb="2rem"
        mb="2.5rem"
        position={"relative"}
        color="var(--primary-text-color)"
      >
        {title}
      </Text>
      <Flex
        flexDirection={"column"}
        alignItems="flex-start"
        fontSize="1.4rem"
        w="100%"
      >
        {content}
      </Flex>
    </Flex>
  );
};

export default CellFooter;
