import { ChevronDownIcon } from "@chakra-ui/icons";
import { Flex, HStack, Image, Link, MenuItem, Text } from "@chakra-ui/react";
import MenuDrawer from "components/menu/MenuDrawer";
import MenuListItem from "components/menu/MenuList";
import { useLocation } from "react-router-dom";
import { MenuListPage } from "utils/constants";
import { routes } from "utils/routes";

const Header = ({ isFixed }: { isFixed?: boolean }) => {
  const location = useLocation();

  return (
    <Flex
      h="8rem"
      w="100%"
      justifyContent={"center"}
      position={isFixed ? "sticky" : "relative"}
      top={0}
      bg={isFixed ? "#fff" : "transparent"}
      transition={"all 0.5s ease"}
      zIndex="10"
      boxShadow={"0px 3px 13px 0px rgb(0 0 0 / 26%)"}
    >
      <Flex
        w={{ base: "100%", md: "100%" }}
        bg={"#fff"}
        h="8rem"
        px={{ md: "5rem", lg: "8.5rem", base: "1.5rem" }}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Link href={routes.Home}>
          <Image
            h="4.5rem"
            src="/img/H_Q_Morison_Logo.png"
            marginLeft={"2rem"}
          ></Image>
        </Link>
        <HStack px="1.5rem" display={{ base: "none", md: "flex" }}>
          {MenuListPage.map((el) => {
            if (el.items && el.items.length > 0)
              return (
                el.items &&
                el.items.length > 0 && (
                  <MenuListItem
                    key={el.title}
                    url={`${el.url}${el.items[0].url}`}
                    name={
                      <HStack
                        alignItems={"center"}
                        color={
                          location.pathname.includes(el.url ?? "sup")
                            ? "var( --orange-color)"
                            : "var(--gray-color)"
                        }
                        whiteSpace="nowrap"
                      >
                        <Text>{el.title} </Text>
                        <ChevronDownIcon />
                      </HStack>
                    }
                  >
                    {el.items.map((sub) => (
                      <MenuItem>
                        <Link
                          key={`${sub.url}`}
                          variant={"linkItem"}
                          href={`${el.url}${sub.url}`}
                          _hover={{
                            color: "var(--orange-color)",
                          }}
                          color={
                            location.pathname.includes(`${el.url}${sub.url}`)
                              ? "var( --orange-color)"
                              : "var(--gray-color)"
                          }
                        >
                          {sub.title}
                        </Link>
                      </MenuItem>
                    ))}
                  </MenuListItem>
                )
              );
            return (
              <Link
                key={el.title}
                variant={"overlay"}
                href={el.url}
                color={
                  location.pathname === (el.url ?? "temp")
                    ? "var( --orange-color)"
                    : "var(--gray-color)"
                }
                _hover={{
                  color: "var(--orange-color)",
                }}
                whiteSpace="nowrap"
              >
                {el.title}
              </Link>
            );
          })}
        </HStack>
        <MenuDrawer />
      </Flex>
    </Flex>
  );
};

export default Header;
