export const drawerStyle = {
  variants: {
    base: {
      display: {
        md: "none",
        base: "block",
      },
    },
  },
};
