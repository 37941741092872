import {
  Box,
  Button,
  Center,
  CenterProps,
  Flex,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import emailjs from "@emailjs/browser";
import { Field, Formik } from "formik";
import CellFooter from "molecules/CellFooter";
import React, { useRef } from "react";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { FaClock, FaLinkedin } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { configs } from "utils/app";

const SocialWraper: React.FC<CenterProps> = ({ children }) => {
  return (
    <Center
      h="3rem"
      w="3rem"
      bg="transparent"
      color="#0a66c2"
      border="1px solid #0a66c2"
      borderRadius={"0.5rem"}
    >
      {children}
    </Center>
  );
};

const Footer = () => {
  const form = useRef<HTMLFormElement>(null);
  const toast = useToast();

  return (
    <Flex
      w={"100%"}
      position={"relative"}
      margin="0 auto"
      justifyContent={"center"}
      flexDirection="column"
      alignItems={"center"}
    >
      <VStack
        py="2rem"
        w={{ base: "100%", md: "85%" }}
        px={{ base: "2rem", md: 0 }}
      >
        <Stack
          w="100%"
          direction={{ base: "column", xl: "row" }}
          spacing="2rem"
        >
          <CellFooter
            title="Contact us"
            content={
              <>
                <VStack
                  w="100%"
                  alignItems={"flex-start"}
                  spacing="1.5rem"
                  fontSize={{ base: "1.2rem", md: "1.5rem" }}
                  color="var(--blue-color)"
                >
                  <Flex alignItems={"center"}>
                    <GrMail /> <Text ml="0.5rem">Email: hello@hqco.com.vn</Text>
                  </Flex>

                  <Flex alignItems={"center"}>
                    <FaClock />{" "}
                    <Text ml="0.5rem">
                      Working hours: 8:30 - 17:30, Monday - Friday
                    </Text>
                  </Flex>
                  <Text as="span">
                    Vietnam Office:{" "}
                    <Link
                      href="https://goo.gl/maps/p8LpdRmr7Fs6Atzx9"
                      isExternal
                    >
                      <Text
                        as="span"
                        fontStyle={"italic"}
                        color="var(--blue-color)"
                        textDecoration={"underline"}
                      >
                        2<Text as="sup">nd</Text> Floor, Vina Home Building,
                        39-41 Le Thach, District 4, Ho Chi Minh City, Vietnam
                      </Text>
                    </Link>
                  </Text>

                  <Text as="span">
                    Singapore Office:{" "}
                    <Link
                      href="https://goo.gl/maps/fPj5EuJSqtXnQwRA7"
                      isExternal
                    >
                      <Text
                        as="span"
                        fontStyle={"italic"}
                        color="var(--blue-color)"
                        textDecoration={"underline"}
                      >
                        10 Anson Road, International Plaza, Singapore
                      </Text>
                    </Link>
                  </Text>

                  <HStack justifyContent={"flex-start"}>
                    <Text>Follow us: </Text>
                    <Link
                      href="https://www.linkedin.com/company/h&qadvisory/posts/?feedView=all&viewAsMember=true"
                      color="#0a66c2"
                      display={"flex"}
                      target="_blank"
                    >
                      <SocialWraper>
                        <FaLinkedin size={"2rem"} />
                      </SocialWraper>
                    </Link>
                  </HStack>
                </VStack>
              </>
            }
            flex={1}
          />
          <CellFooter
            title=""
            content={
              <>
                <VStack
                  w={{ base: "100%", md: "70%" }}
                  alignItems={"flex-start"}
                  spacing="1.5rem"
                  fontSize={{ base: "1.2rem", md: "1.5rem" }}
                  color="var(--blue-color)"
                  alignSelf={{ base: "center", xl: "flex-end" }}
                >
                  <Formik
                    initialValues={{
                      email: "",
                      name: "",
                      message: "",
                    }}
                    onSubmit={() => {
                      const current = form.current;
                      if (current) {
                        emailjs
                          .sendForm(
                            configs.serviceId,
                            configs.templateId,
                            current,
                            configs.publicId,
                          )
                          .then(
                            (result) => {
                              toast({
                                title: (
                                  <Text
                                    fontSize={"1.4rem"}
                                    color="white"
                                    h="3rem"
                                  >
                                    Success
                                  </Text>
                                ),
                                description: (
                                  <Text fontSize={"1.4rem"}>
                                    Thank you for contacting us
                                  </Text>
                                ),
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                              });
                              current.reset();
                            },
                            (error) => {
                              //
                              console.log(error);
                            },
                          );
                      }
                    }}
                  >
                    {({ handleSubmit, errors, touched }) => (
                      <Box
                        as="form"
                        ref={form}
                        onSubmit={handleSubmit}
                        w="100%"
                      >
                        <VStack w="100%" spacing={"2rem"}>
                          <FormControl
                            isInvalid={!!errors.name && touched.name}
                          >
                            <Field
                              name="name"
                              size={"lg"}
                              h="4rem"
                              as={InputGroup}
                              validate={(value: string) => {
                                let error;

                                if (!value) {
                                  error = "invalid name";
                                }

                                return error;
                              }}
                            >
                              <InputLeftElement pointerEvents="none" h="100%">
                                <AiOutlineUser
                                  size={"1.5rem"}
                                  color="var(--blue-color)"
                                />
                              </InputLeftElement>
                              <Input
                                placeholder="Name"
                                fontSize={"1.6rem"}
                                h="100%"
                                name="name"
                                boxShadow={"none !important"}
                              />
                            </Field>
                          </FormControl>
                          <FormControl
                            isInvalid={!!errors.email && touched.email}
                          >
                            <Field
                              name="email"
                              size={"lg"}
                              h="4rem"
                              type="email"
                              as={InputGroup}
                              validate={(value: string) => {
                                let error;

                                if (
                                  !value ||
                                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                    value,
                                  )
                                ) {
                                  error = "invalid email";
                                }

                                return error;
                              }}
                            >
                              <InputLeftElement pointerEvents="none" h="100%">
                                <AiOutlineMail
                                  size={"1.5rem"}
                                  color="var(--blue-color)"
                                />
                              </InputLeftElement>
                              <Input
                                placeholder="Email"
                                fontSize={"1.6rem"}
                                h="100%"
                                name="email"
                                boxShadow={"none !important"}
                              />
                            </Field>
                          </FormControl>
                          <FormControl
                            isInvalid={!!errors.email && touched.email}
                          >
                            <Field
                              name="message"
                              size={"lg"}
                              type="message"
                              as={InputGroup}
                              validate={(value: string) => {
                                let error;

                                if (!value) {
                                  error = "message is required";
                                }

                                return error;
                              }}
                            >
                              <Textarea
                                rows={5}
                                placeholder="Message"
                                fontSize={"1.6rem"}
                                name="message"
                                boxShadow={"none !important"}
                              />
                            </Field>
                          </FormControl>

                          <Button
                            variant="outline"
                            borderRadius={0}
                            h="4rem"
                            w="15rem"
                            borderColor={"var(--orange-color)"}
                            color="var(--orange-color)"
                            fontSize={"1.6rem"}
                            fontWeight="medium"
                            bg="white"
                            _hover={{
                              bg: "var(--orange-color)",
                              color: "white",
                            }}
                            type="submit"
                          >
                            Send message
                          </Button>
                        </VStack>
                      </Box>
                    )}
                  </Formik>
                </VStack>
              </>
            }
            flex={1}
          />
        </Stack>
      </VStack>
    </Flex>
  );
};

export default Footer;
