export const textStyle = {
  baseStyle: {},
  variants: {
    header: {
      fontWeight: "bold",
      fontSize: {
        base: "2.5rem",
        md: "3.8rem",
      },
    },
    title: {
      fontSize: {
        base: "1.6rem",
        md: "2.5rem",
      },
      color: "black",
    },
    description: {
      fontSize: {
        base: "1.6rem",
        md: "2rem",
      },
    },
    sub: {
      fontSize: {
        base: "1.4rem",
        md: "1.6rem",
      },
    },
    project: {
      fontSize: {
        base: "1.2rem",
        md: "1.4rem",
      },
    },
    head: {
      fontSize: {
        base: "1.4rem",
        md: "1.8rem",
      },
    },
  },
};
