import {
  Box,
  Flex,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import MenuCollapse from "components/menu/MenuCollapse";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MenuListPage, services } from "utils/constants";
import { routes } from "utils/routes";

const Services = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState(`/${id}`);

  const detail = useMemo(() => {
    const item = services.find((el) => el.id === currentId);
    if (item) {
      return (
        <VStack py="3rem" alignItems={"flex-start"} spacing="3rem" w="100%">
          <Box
            bgImage={`url('${item.img}')`}
            bgRepeat="no-repeat"
            bgSize={"cover"}
            w="100%"
            minH={{ lg: "40rem", md: "50rem", base: "30rem" }}
            position={"relative"}
          >
            <Box
              position="absolute"
              left="10%"
              right="10%"
              top="50%"
              h="30%"
              bg="rgba(255,255,255,0.9)"
              transform={"translateY(-50%)"}
              display="flex"
              justifyContent={"center"}
              alignItems="center"
            >
              <Text
                color="var(--blue-color)"
                fontWeight={"bold"}
                textAlign="center"
                variant="header"
              >
                {item.title}
              </Text>
            </Box>
          </Box>

          <Box position={"relative"} w="100%" mt="4rem">
            <Box
              w="100%"
              h="1rem"
              _before={{
                content: "''",
                display: "block",
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "4rem",
                backgroundImage: "url('/img/dots-bg-repeat.png')",
                backgroundRepeat: "repeat-x",
                backgroundSize: "contain",
                backgroundPosition: "50% 50%",
              }}
            ></Box>
          </Box>

          <VStack
            w="100%"
            fontSize={"1.6rem"}
            spacing="1.5rem"
            alignItems="flex-start"
            color="black"
          >
            {item.descriptions?.map((el, index) => (
              <Text
                variant={"description"}
                lineHeight={"2.2rem"}
                color={index === 0 ? "var(--blue-color)" : "black"}
                fontWeight={index === 0 ? "bold" : "normal"}
                key={el}
              >
                {el}
              </Text>
            ))}
          </VStack>

          {item.unorderedList && (
            <UnorderedList
              fontSize={"1.6rem"}
              ml="3rem"
              color="black"
              spacing={"1.5rem"}
            >
              {item.unorderedList.map((item, index) => (
                <ListItem lineHeight={"2.2rem"} key={String(index) + "item"}>
                  <Text variant={"description"}> {item}</Text>
                </ListItem>
              ))}
            </UnorderedList>
          )}
        </VStack>
      );
    }
    return (
      <Text
        color="var(--primary-text-color)"
        fontWeight={"bold"}
        textAlign="center"
        variant="header"
      >
        Content Not Found!
      </Text>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);
  const service = MenuListPage[2];

  useEffect(() => {
    const newId = id ? `/${id}` : undefined;
    if (newId && newId !== currentId) setCurrentId(newId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const container = document.getElementById("root-container");
    container?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentId]);

  return (
    <HStack alignItems={"flex-start"} pt="2rem" pb="4rem">
      <Box
        w={{ base: "10rem", "2sm": "20rem", lg: "30rem" }}
        minH="20rem"
        py="3rem"
        display={{ base: "none", "2sm": "block" }}
        transition="all .5s ease-in-out"
      >
        <MenuCollapse
          isOpen
          title={service.title}
          items={service.items}
          onClickItem={(id?: string) => () => {
            setCurrentId(id ?? "");
            navigate(`${routes.ServiceDetail}${id}`, { replace: true });
          }}
          onToggle={() => {
            //
          }}
          hasArrow={false}
          buttonProps={{
            color: "var(--blue-color)",
            fontSize: "1.6rem",
          }}
          listProps={{
            mt: "2rem",
          }}
          linkProps={{
            fontWeight: "medium",
            color: "var(--blue-color)",
          }}
        />
      </Box>
      <Box flex={1} position="relative">
        <Flex
          w={"100%"}
          position={"relative"}
          margin="0 auto"
          justifyContent={"center"}
          flexDirection="column"
          alignItems={"center"}
        >
          <Box w={"100%"} px={{ base: "0rem", md: "2rem" }}>
            {detail}
          </Box>
        </Flex>
      </Box>
    </HStack>
  );
};

export default Services;
