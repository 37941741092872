import { Badge, Box, HStack, StackProps, Text, VStack } from "@chakra-ui/react";
import { IoLocationOutline } from "react-icons/io5";

const ProjectCell = ({
  subject,
  category,
  title,
  status,
  location,
  src,
  isCompleted,
  ...rest
}: {
  subject: string;
  category: string;
  title: string;
  status: string;
  location: string;
  src: string;
  isCompleted?: boolean;
} & StackProps) => {
  return (
    <VStack
      w="calc(100% - 4rem)"
      h="20rem"
      position="relative"
      bg="white"
      boxShadow="0 0 15px rgba(8,40,85,.1)"
      justifyContent={"space-between"}
      alignItems="center"
      overflow={"hidden"}
      backgroundSize={"cover"}
      m="1rem"
      _hover={{
        transition: ".2s ease-in-out",
        transform: "scale(1.02) !important",
        opacity: 0.7,
        cursor: "pointer",
      }}
      {...rest}
    >
      <Box position={"absolute"} top="0" right="0">
        <Badge
          fontSize={"1.6rem"}
          color="white"
          py="1rem"
          px="2rem"
          bg="var(--orange-color)"
          borderRadius={0}
        >
          <Text> {category}</Text>
        </Badge>
      </Box>

      <VStack
        w="100%"
        h="15rem"
        position={"absolute"}
        bottom="0"
        left={"0"}
        right="0"
        bg={"rgba(255,255,255,.7)"}
        p="1rem"
        color={"var(--blue-color)"}
      >
        <HStack justifyContent={"center"} alignItems={"center"}>
          <Text fontWeight={"bold"} variant={"title"} color="var(--blue-color)">
            {subject}
          </Text>
          <Badge
            fontSize={"1rem"}
            color={"white"}
            py="0.5rem"
            px="1rem"
            bg={"var(--blue-color)"}
            borderRadius={0}
            ml="1rem"
          >
            {isCompleted ? "Completed" : "On going"}
          </Badge>
        </HStack>

        <Text fontWeight={"bold"} variant={"head"}>
          {title}
        </Text>
        {status && (
          <Text fontWeight={"bold"} variant={"project"}>
            {status}
          </Text>
        )}
        {location && (
          <HStack
            fontSize="1.4rem"
            width="100%"
            alignItems={"center"}
            justifyContent="center"
          >
            <IoLocationOutline /> <Text variant={"project"}>{location}</Text>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export default ProjectCell;
