import { Box, useMediaQuery } from "@chakra-ui/react";
import ProjectCell from "components/project/ProjectCell";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { completedProject } from "utils/constants";

const Project = () => {
  const [isLarger] = useMediaQuery("(min-width: 1300px)");
  const [isSmaller] = useMediaQuery("(max-width: 700px)");
  return (
    <Box
      margin="0 auto"
      py="5rem"
      w="100%"
      px={{ base: "0rem", md: "5rem" }}
      position={"relative"}
    >
      <Swiper
        slidesPerView={isSmaller ? 1 : isLarger ? 3 : 2}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay, Navigation]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {completedProject.map((el, index) => (
          <SwiperSlide key={index}>
            <ProjectCell {...el} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Project;
