import { Box, Stack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import Partner from "molecules/Partner";

const OurClients = () => {
  const partners = [
    {
      image: "1.Kidogrouplogo.png",
      src: "https://www.kdc.vn/",
    },
    {
      image: "2.VinaCapital.jpg",
      src: "https://vinacapital.com/",
      imgWrapProps: {
        width: "100%",
      },
    },
    {
      image: "3.HungThinhCorp.png",
      src: "https://hungthinhcorp.com.vn/",
    },
    {
      image: "4.HungThinhLand.png",
      src: "https://www.hungthinhland.com/",
      imgWrapProps: {
        width: "80%",
      },
    },
    {
      image: "5.TTF.jpeg",
      src: "https://www.truongthanh.com/",
    },
    {
      image: "6.TrungThuy.png",
      src: "https://trungthuy.com/",
    },
    {
      image: "7.KonanJapan.jpeg",
      src: "https://www.hc-kohnan.com/eng/profile/",
    },
    {
      image: "8.NamAnMarket.jpeg",
      src: "https://namanmarket.com/",
    },
    {
      image: "9.Auchan.png",
      src: "https://www.auchan-retail.com/en/",
    },
    {
      image: "10.Ipsen.jpeg",
      src: "https://www.ipsen.com/",
    },
    {
      image: "11.Gintell.jpg",
      src: "https://www.gintell.com",
    },
    {
      image: "12.EQUO.png",
      src: "https://shopequo.com/vi",
    },
    {
      image: "13.TheCoffeeHouse.jpeg",
      src: "https://thecoffeehouse.com/",
    },
    {
      image: "14.CoffeeBeanandTeaLeafs.jpeg",
      src: "https://coffeebean.com.vn/en",
    },
    {
      image: "15.WestWayDental.jpeg",
      src: "https://westwaydental.vn/",
      imgWrapProps: {
        width: "100%",
      },
    },
    {
      image: "16.FusionMaia.png",
      src: "https://fusionresorts.com",
    },
    {
      image: "17.LifeResortHoiAn.jpg",
      src: "https://www.anantara.com/vi/hoi-an",
      imgWrapProps: {
        width: "100%",
      },
    },
    {
      image: "18.UtUt.jpeg",
      src: "https://www.quanutut.com/",
    },
    {
      image: "19.MTPEntertainment.jpeg",
      src: "https://mtpentertainment.com",
    },
    {
      image: "20.YanNews.jpeg",
      src: "https://www.yan.vn/",
    },
    {
      image: "21.Blueseed.jpeg",
      src: "https://blueseed.group",
    },
    {
      image: "22.AprilAdvertising.jpeg",
      src: "https://april.com.vn/en/home/",
    },
    {
      image: "23.DataFirst.jpg",
      src: "",
      imgWrapProps: {
        width: "100%",
      },
    },
    {
      image: "24.ADVA.png",
      src: "https://advahealthsolutions.com",
      imgWrapProps: {
        width: "100%",
      },
    },
    {
      image: "25.Bootloader.jpeg",
      src: "https://bootloader.studio",
    },
    {
      image: "26.Appota.png",
      src: "https://appota.com/",
      imgWrapProps: {
        width: "100%",
      },
    },
    {
      image: "27.InspireVentures.png",
      src: "https://mobile.inspireventures.com/",
    },
    {
      image: "28.IDGVentures.png",
      src: "https://idgvv.com.vn/en/",
    },
    {
      image: "29.Deliveree.png",
      src: "https://www.deliveree.com/",
    },
    {
      image: "30.Giaohangnhanh.webp",
      src: "https://ghn.vn/",
      imgWrapProps: {
        width: "100%",
      },
    },
  ];
  // get all file name in folder /pubic/img/partners
  return (
    <>
      <Box w={"100%"} px={{ base: "0rem", md: "7rem" }} pt="2rem" pb="4rem">
        <Box position={"relative"} w="100%" my="2rem">
          <Box
            w="100%"
            h="1rem"
            _before={{
              content: "''",
              display: "block",
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              height: "4rem",
              backgroundImage: "url('/img/dots-bg-repeat.png')",
              backgroundRepeat: "repeat-x",
              backgroundSize: "contain",
            }}
          ></Box>
        </Box>
        <Stack
          direction={{ md: "row", base: "column" }}
          spacing={"1rem"}
          justifyContent="left"
          alignItems={"left"}
          mb="4rem"
        >
          <Box px={{ base: "2rem", md: 0 }}>
            <Text variant={"header"} fontWeight="bold" color="#122c6c">
              Our Major Clients
            </Text>
          </Box>
        </Stack>

        <Wrap align="center" spacing={"0"} spacingY="1.5rem">
          {partners.map((el) => (
            <WrapItem
              w={{
                md: "calc(100% / 6)",
                base: "calc(100% / 3)",
              }}
              h="100%"
            >
              <Partner
                src={`/img/partners/${el.image}`}
                partnerSite={el.src}
                {...el.imgWrapProps}
              />
            </WrapItem>
          ))}
        </Wrap>
      </Box>
    </>
  );
};

export default OurClients;
