import { Box, Flex, Stack, Text, VStack } from "@chakra-ui/react";
import NewsItem from "components/news/NewsItem";

const News = () => {
  const news = [
    {
      title:
        "H&Q Advisory officially become an Independent Member of Morison Global, a leading international association of high-quality professional services firms.",
      description:
        "Morison Global has further strengthened its representation in Vietnam by welcoming H&Q Advisory, an accounting, corporate finance and consulting firm located in Ho Chi Minh City...",
      link: "https://www.morisonglobal.com/news/morison-global-welcomes-vietnam-firm-h-q-audit-advisory",
      src: "/img/partners/morisonglobal_logo.svg",
      newsDate: "2023/03/23",
    },
    {
      title:
        " H&Q Advisory became a Strategic Alliance Partner with AvistaAdvisory, a leading pan-Asian investment bank.",
      description:
        "Avista Advisory Group proudly announces its strategic alliance with H&Q Advisory, Vietnam. This strategic alliance will strengthen the resources and mandate execution capabilities of both the companies...  ",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:6946094433268301825/",
      src: "/img/partners/Avista-Logo.png",
      newsDate: "2023/03/23",
    },
  ];

  return (
    <>
      <Flex
        w={"100%"}
        minH="50rem"
        position={"relative"}
        margin="0 auto"
        justifyContent={"center"}
        flexDirection="column"
        alignItems={"center"}
      >
        <Box w={"100%"} px={{ base: "0rem", md: "7rem" }} pt="2rem" pb="4rem">
          <Stack
            direction={{ md: "row", base: "column" }}
            spacing={"1rem"}
            justifyContent="left"
            alignItems={"left"}
            my="4rem"
          >
            <Box px={{ base: "2rem", md: 0 }}>
              <Text
                variant={"header"}
                fontWeight="bold"
                color="#122c6c"
                position={"relative"}
                _before={{
                  content: "''",
                  position: "absolute",
                  left: "0",
                  bottom: "0",
                  top: "calc(100% + 10px)",
                  height: "0.5rem",
                  width: "80%",
                  backgroundColor: "rgb(246, 142, 19) !important",
                }}
              >
                Latest News
              </Text>
            </Box>
          </Stack>

          {news.map((item) => (
            <VStack py="3rem" alignItems={"flex-start"} spacing="1.5rem">
              <NewsItem
                title={item.title}
                src={item.src}
                description={item.description}
                key={item.link}
                newsDate={item.newsDate}
                link={item.link}
              />
            </VStack>
          ))}
        </Box>
      </Flex>
    </>
  );
};

export default News;
