export const buttonStyle = {
  variants: {
    btnCollapse: {
      w: "100%",
      justifyContent: "space-between",
      fontWeight: "bold",
      padding: "1rem 0",
      opacity: 0.7,
      fontSize: "1.4rem",
      color: "#252525",
      background: "transparent",
      _hover: {
        color: "#f68e13",
      },
    },
    buttonCollapseSub: {
      w: "100%",
      justifyContent: "space-between",
      fontWeight: "bold",
      padding: "1rem 0",
      opacity: 0.7,
      fontSize: "1.4rem",
      color: "#252525",
      background: "transparent",
      _hover: {
        color: "#f68e13",
      },
    },
    btnContact: {
      fontWeight: "bold",
      h: "4.5rem",
      borderRadius: "3rem",
      px: "4rem",
      backgroundImage: "linear-gradient(90deg, #FD7E14 0%, #FB3F3F 100%)",
      _hover: {
        background: "#14305d !important",
      },
      fontSize: "1.4rem",
      color: "#fff",
    },
  },
};
