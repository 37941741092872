import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Flex, Image, Stack, Text } from "@chakra-ui/react";

const NewsItem = ({
  src,
  title,
  link,
  newsDate,
  description,
}: {
  src: string;
  title: string;
  description?: string;
  newsDate?: string;
  link?: string;
}) => {
  return (
    <Stack
      w={"100%"}
      h="100%"
      bg="white"
      boxShadow={"0 20px 70px -10px rgba(8,40,85,.4)"}
      color="var(--blue-color)"
      _hover={{
        cursor: "pointer",
        ".short-info": {
          bg: "var(--blue-color)",
          color: "rgba(255,255,255,0.8)",
          transition: ".2s ease-in-out",
        },
        ".read-more": {
          color: "var(--orange-color)",
        },
        ".service-title": {
          color: "white",
        },
      }}
      position="relative"
      alignItems="center"
      direction={{ base: "column", md: "row" }}
    >
      <Flex
        w={{ base: "100%", md: "30%" }}
        h="100%"
        bg="white"
        py="1rem"
        px="2rem"
        justifyContent={"center"}
        alignItems="center"
      >
        <Image src={src} fit="contain" />
      </Flex>
      <Flex
        flexDirection={"column"}
        p="2rem"
        flex={1}
        position="relative"
        alignItems={"flex-start"}
        h="100%"
        alignSelf={"flex-start"}
        className="short-info"
      >
        <Text
          variant="title"
          color="var(--blue-color)"
          fontWeight={"bold"}
          className="service-title"
          wordBreak={"break-word"}
        >
          {title}
        </Text>
        <Text
          variant={"sub"}
          color="var(--gray-color)"
          fontStyle={"italic"}
          flex={1}
          py="1rem"
        >
          {description}
        </Text>
        <Button
          rightIcon={<ArrowForwardIcon />}
          variant="outline"
          borderRadius={0}
          h="5.5rem"
          w="15rem"
          borderColor={"var(--blue-color)"}
          color="var(--blue-color)"
          fontSize={"1.6rem"}
          fontWeight="medium"
          bg="white"
          fontStyle={"italic"}
          onClick={() => {
            window.open(link, "_blank");
          }}
          alignSelf="flex-end"
        >
          Read more...
        </Button>
      </Flex>
    </Stack>
  );
};

export default NewsItem;
