import "assets/styles/index.scss";
import LayoutContainer from "components/layout/LayoutContainer";
import AboutUsPage from "pages/about-us";
import Home from "pages/home";
import News from "pages/news";
import OurPartners from "pages/partners";
import Services from "pages/services";
import OurTeams from "pages/teams";
import { Route, Routes } from "react-router-dom";
import { routes } from "utils/routes";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LayoutContainer />}>
        <Route index element={<Home />} />
        <Route path={routes.OurPartners} element={<OurPartners />} />
        <Route path={`${routes.ServiceDetail}/:id`} element={<Services />} />
        <Route path={routes.News} element={<News />} />
        <Route
          path={`${routes.AboutUs}${routes.AboutTeam}`}
          element={<OurTeams />}
        />
        <Route path={`${routes.AboutUs}/about-us`} element={<AboutUsPage />} />
      </Route>
    </Routes>
  );
}

export default App;
