export const routes = {
  Home: "/",
  AboutUs: "/AboutUs",
  AboutTeam: "/AboutTeam",
  OurServices: "/OurServices",
  CorporateFinance: "/CorporateFinance",
  FinancialAdvisoryServices: "/FinancialAdvisoryServices",
  TaxationServices: "/TaxationServices",
  AccountancyServices: "/AccountancyServices",
  PayrollServices: "/PayrollServices",
  BusinessStartUpServices: "/BusinessStartUpServices",
  DueDilligence: "/DueDilligence",
  RiskAdvisoryServices: "/RiskAdvisoryServices",
  OurPartners: "/partners",
  News: "/news",
  ServiceDetail: "/services",
};
