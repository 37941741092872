import { Box, Image, Text, VStack } from "@chakra-ui/react";

const ProfileCard = ({
  src,
  name,
  position,
  isSelected,
}: {
  name: string;
  src: string;
  position?: string;
  isSelected?: boolean;
}) => {
  return (
    <VStack
      w="100%"
      p="1.5rem"
      pt="0"
      overflow={"hidden"}
      boxShadow="0 0 15px rgba(8,40,85,.4)"
      transition=".5s ease-in-out"
      border={isSelected ? "1px solid var(--orange-color)" : undefined}
      boxSizing="border-box"
      _hover={{
        cursor: "pointer",
        transform: "scale(1.05) !important",
      }}
    >
      <VStack position="relative">
        <Image src={src} h="45rem" fit="cover" />
        <Box
          position={"absolute"}
          bottom="0"
          left={"0"}
          right="0"
          py="2rem"
          bg="rgba(0,58,106,.8)"
        >
          <Text
            variant="title"
            color="white"
            fontWeight={"bold"}
            className="service-title"
            textAlign={"center"}
          >
            {name}
          </Text>
        </Box>
      </VStack>

      <Text variant={"sub"} flex={1} py="1rem" textAlign={"center"}>
        {position}
      </Text>
    </VStack>
  );
};

export default ProfileCard;
