import { Flex } from "@chakra-ui/react";
import Intro from "components/intro";
import OurClients from "components/our-clients";
import OurServices from "components/our-services";
import Project from "components/project";

const Home = () => {
  return (
    <>
      <Project />
      <Intro />
      <Flex
        w={"100%"}
        minH="50rem"
        position={"relative"}
        margin="0 auto"
        justifyContent={"center"}
        flexDirection="column"
        alignItems={"center"}
      >
        <OurServices />
        <OurClients />
      </Flex>
    </>
  );
};

export default Home;
