import {
  Box,
  Flex,
  Link,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";

const OurPartners = () => {
  const partners = [
    {
      title: "Morison Global",
      img: "/img/partners/morisonglobal_logo.svg",
      link: "https://www.morisonglobal.com/",
      description: [
        "Morison Global is a leading international association of high-quality, independently owned and managed professional services firms.",
        "Morison Global’s objective is to provide its members and their clients with comprehensive access to high-quality professional services in key markets around the world. Its global reputation brings trust and confidence to the markets in which it serves.",
        "Members provide core services of accounting, audit and tax and have capabilities in complementary services such as corporate finance, advisory, consulting and law",
        "Morison Global’s firms are leaders in their professional communities. They aim to constantly improve their firms and strengthen their clients’ position. We are proud of our community’s culture: supportive, inclusive, professional and always friendly.",
        "With members in over 75 countries, Morison Global is truly international. From well-established market leaders in key economic centres to dynamic, young, forward-thinking firms in newly developed markets – Morison Global is a rich, diverse and dynamic community able to meet complex global client requirements.",
      ],
    },
    {
      title: "Avista Advisory",
      img: "/img/partners/Avista-Logo.png",
      link: "https://avistaadvisory.com/",
      description: [
        "Avista Advisory is a boutique Investment Banking advisory firm, which over the last two decades has established itself as a well-entrenched and proven franchise across India & Southeast Asia. ",
        "Avista Advisory has successfully combined their deep domain expertise, understanding of business and cultural dynamics and their robust network of financial sponsors and corporates to execute even the most complex M&A, Restructuring and Private Finance transactions;",
        "Our teams in H&Q and Avista Advisory are together to have a strong track record and experience in commitments to bring profitable investment transactions and execution to Vietnam.",
      ],
      height: { base: "5rem", md: "9rem" },
    },
    {
      title: "Viet & Co Auditing",
      img: "/img/partners/NewVietCo.png",
      link: "http://vnco.com.vn",
      description: [
        "Viet & Co was incorporated as a privately-owned professional service firm providing accounting and auditing, consulting, and tax advisory services to all economic organizations in various sectors on a national scale.	",
        "Viet & Co was founded by several qualified auditors on finance, accounting, taxation, and management. Viet & Co have 2 partners, 5 directors and professional staff strength of about 35.",
        "Viet & Co has been an Independent Member of Morison Global since 2009.",
      ],
      height: { base: "7rem", md: "12rem" },
    },
    {
      title: "HP Advisory",
      img: "/img/partners/hpLogo.png",
      link: "https://www.hp-advisory.com/",
      description: [
        "HP Advisory Pte. Ltd. (“HP Advisory”) is a financial advisory firm in Singapore led by Mr. Paul Wan and Mr. Nguyen Huy Hoang (King Nguyen), with our mainly focus in Vietnam;",
        "We also owned and managing a local advisory firm in Vietnam - H&Q Advisory Company Limited (“H&Q Advisory”). H&Q Advisory was founded in November 2014 and is an Independent Member of Morison Global;",
        "Our teams in H&Q/HP Advisory are together to have a strong track record and experience in commitments to bring profitable investment transaction and execution to Vietnam.",
      ],
    },
  ];

  return (
    <>
      <Flex
        w={"100%"}
        minH="50rem"
        position={"relative"}
        margin="0 auto"
        justifyContent={"center"}
        flexDirection="column"
        alignItems={"center"}
      >
        <Box w={"100%"} px={{ base: "0rem", md: "7rem" }} pt="2rem" pb="4rem">
          <Stack
            direction={{ md: "row", base: "column" }}
            spacing={"1rem"}
            justifyContent="left"
            alignItems={"left"}
            py="4rem"
            px="1rem"
          >
            <Box px={{ base: "2rem", md: 0 }}>
              <Text
                variant={"header"}
                fontWeight="bold"
                color="#122c6c"
                position={"relative"}
                _before={{
                  content: "''",
                  position: "absolute",
                  left: "0",
                  bottom: "0",
                  top: "calc(100% + 10px)",
                  height: "0.5rem",
                  width: "10rem",
                  backgroundColor: "rgb(246, 142, 19) !important",
                }}
              >
                Our Partners
              </Text>
            </Box>
          </Stack>
          <Tabs isFitted variant="unstyled" mt="4rem">
            <TabList>
              {partners.map((item) => (
                <Tab>
                  <Box
                    backgroundImage={`url('${item.img}')`}
                    backgroundRepeat="no-repeat"
                    backgroundSize="contain"
                    backgroundAttachment="scroll"
                    backgroundPosition="50% 50%"
                    h={item.height ?? { base: "5rem", md: "10rem" }}
                    w={"80%"}
                    position={"relative"}
                  ></Box>
                </Tab>
              ))}
            </TabList>

            <TabIndicator
              mt="1.5px"
              height="0.5rem"
              bg="var(--orange-color)"
              borderRadius="1px"
            />
            <TabPanels>
              {partners.map((item) => (
                <TabPanel>
                  <VStack py="3rem" alignItems={"flex-start"} spacing="1.5rem">
                    <Box position={"relative"} w="100%" my="2rem">
                      <Box
                        w="100%"
                        h="1rem"
                        _before={{
                          content: "''",
                          display: "block",
                          position: "absolute",
                          left: 0,
                          right: 0,
                          bottom: 0,
                          width: "100%",
                          height: "4rem",
                          backgroundImage: "url('/img/dots-bg-repeat.png')",
                          backgroundRepeat: "repeat-x",
                          backgroundSize: "contain",
                        }}
                      ></Box>
                    </Box>
                    <VStack
                      alignItems={"flex-start"}
                      spacing="0"
                      borderLeft="0.5rem solid var(--orange-color)"
                      pl="1rem"
                      w="100%"
                      bg="rgb(237,242,247)"
                    >
                      <Text
                        color="var(--primary-text-color)"
                        fontWeight={"bold"}
                        textAlign="center"
                        variant="header"
                      >
                        {item.title}
                      </Text>
                      <Link
                        href={item.link}
                        target="_blank"
                        fontStyle="italic"
                        color="#122c6c"
                      >
                        <Text variant="description">{item.link}</Text>
                      </Link>
                    </VStack>

                    {item.description.map((el, _index) => (
                      <Text
                        variant={"description"}
                        color="black"
                        key={String(_index) + "des"}
                      >
                        {el}
                      </Text>
                    ))}
                  </VStack>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </>
  );
};

export default OurPartners;
