import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Image, Stack, Text } from "@chakra-ui/react";

const ServiceCard = ({
  src,
  title,
  description,
}: {
  src: string;
  title: string;
  description?: string;
}) => {
  return (
    <Stack
      w={"100%"}
      h="100%"
      minH="25rem"
      bg="white"
      boxShadow="0 0 15px rgba(8,40,85,.4)"
      color="var(--blue-color)"
      _hover={{
        bg: "var(--blue-color)",
        color: "rgba(255,255,255,0.8)",
        transition: ".2s ease-in-out",
        ".service-img": {
          transform: "scale(1.2) !important",
        },
        ".read-more": {
          color: "var(--orange-color)",
        },
        ".service-title": {
          color: "white",
        },
      }}
      position="relative"
      alignItems="flex-start"
      direction={{ base: "column", xl: "row" }}
    >
      <Box
        w={{ base: "100%", xl: "50%" }}
        maxH={{ base: "23rem", xl: "unset" }}
        h="100%"
        overflow={"hidden"}
      >
        <Image
          className="service-img"
          transform="scale(1)"
          transition=".5s ease-in-out"
          src={src}
          w="100%"
          h="100%"
        />
      </Box>
      <Flex flexDirection={"column"} h="100%" padding="2rem" flex={1}>
        <Text
          variant="title"
          color="var(--blue-color)"
          fontWeight={"bold"}
          className="service-title"
        >
          {title}
        </Text>
        <Text variant={"sub"} flex={1} py="1rem">
          {description}
        </Text>

        <Button
          rightIcon={<ArrowForwardIcon />}
          alignSelf="flex-end"
          justifySelf={"flex-end"}
          variant="outline"
          borderRadius={0}
          h="5.5rem"
          w="15rem"
          borderColor={"var(--blue-color)"}
          color="var(--blue-color)"
          fontSize={"1.6rem"}
          fontWeight="medium"
          bg="white"
          fontStyle={"italic"}
        >
          Read more...
        </Button>
      </Flex>
    </Stack>
  );
};

export default ServiceCard;
