import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonProps,
  Collapse,
  Divider,
  Link,
  LinkProps,
  StackProps,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

interface Props {
  title?: string;
  items?: {
    title: string;
    url?: string;
    items?: {
      title: string;
      url?: string;
    }[];
  }[];
  isOpen?: boolean;
  hasArrow?: boolean;
  listProps?: StackProps;
  buttonProps?: ButtonProps;
  linkProps?: LinkProps;
  onToggle?: () => void;
  onClickItem: (id?: string) => () => void;
}

const MenuCollapse = ({
  title,
  items,
  listProps,
  linkProps,
  buttonProps,
  hasArrow = true,
  isOpen: _isOpen,
  onToggle: _onToggle,
  onClickItem,
}: Props) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: _isOpen });
  const location = useLocation();

  return (
    <>
      <Button
        variant={"btnCollapse"}
        onClick={_onToggle ?? onToggle}
        {...buttonProps}
      >
        {title}
        {hasArrow && (
          <ChevronDownIcon transform={isOpen ? "unset" : "rotate(-90deg)"} />
        )}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <VStack ml="1rem" divider={<Divider />} w="25rem" {...listProps}>
          {items?.map((el, index) => {
            if (el.items && el.items?.length > 0) {
              return (
                <MenuCollapse
                  key={String(index)}
                  title={el.title}
                  items={el.items ?? []}
                  listProps={{ w: `21rem` }}
                  onClickItem={onClickItem}
                />
              );
            }
            return (
              <Link
                variant={"linkItem"}
                opacity="0.7"
                w="100%"
                key={String(index)}
                onClick={onClickItem(el.url)}
                {...linkProps}
                color={
                  location.pathname.includes(el.url ?? "temp")
                    ? "var(--orange-color)"
                    : linkProps?.color ?? "var(--gray-color)"
                }
              >
                {el.title}
              </Link>
            );
          })}
          <Box />
        </VStack>
      </Collapse>
    </>
  );
};

export default MenuCollapse;
