import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react";

const Intro = () => {
  return (
    <Box
      margin="0 auto"
      w="100%"
      px={{ base: "0rem", md: "7rem" }}
      position={"relative"}
    >
      <Stack
        direction={{ md: "row", base: "column" }}
        w="100%"
        spacing={"1rem"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <VStack alignItems={"flex-start"} spacing="2rem" w={"100%"}>
          <Text
            fontSize={{ base: "3rem", md: "5rem" }}
            lineHeight="6.5rem"
            fontWeight="bold"
            color="var(--blue-color)"
            position={"relative"}
            _before={{
              content: "''",
              position: "absolute",
              left: "0",
              bottom: "0",
              top: "calc(100% + 10px)",
              height: "0.5rem",
              width: "80%",
              backgroundColor: "rgb(246, 142, 19) !important",
            }}
          >
            H&Q Advisory
          </Text>
          <HStack w="100%" position="relative" zIndex={1}>
            <VStack
              borderRadius={"1.5rem"}
              alignItems={"flex-start"}
              spacing="2rem"
              mt="1rem"
              w="100%"
              py="3rem"
            >
              <Text variant={"title"} textAlign="justify">
                We are a professional firm providing Strategic Corporate
                Finance, Financial Advisory Services, M&A, Due Diligence,
                Accounting and Tax advisory to all economic organizations in
                various sectors on a national scale.
              </Text>
              <Text variant={"title"} textAlign="justify">
                We are an Independent Member of Morison Global, a leading
                international association of high-quality professional services
                firms. Underpinned by its values, Morison Global collaborates to
                serve the cross-border accounting, auditing, tax and consulting
                needs of its members’ clients.
              </Text>
            </VStack>
          </HStack>
        </VStack>
        {/* 
        TBD
        <Box
          bgImage={"url(/img/mask.png)"}
          bgRepeat="no-repeat"
          bgSize={"contain"}
          bgPosition="50% 50%"
          w={{ base: "100%", "2sm": "80%", xl: "70%" }}
          height={"100%"}
        >
          <Box position="relative" zIndex={1} w="100%">
            <Box display={"inline-block"} position="relative" w="100%">
              <Center>
                <Image src="/img/slider-home.png" className="mask-image" />
              </Center>
            </Box>
          </Box>
        </Box> */}
      </Stack>
    </Box>
  );
};

export default Intro;
