import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";

const AboutUsPage = () => {
  return (
    <Flex
      w={"100%"}
      minH="50rem"
      position={"relative"}
      margin="0 auto"
      justifyContent={"center"}
      flexDirection="column"
      alignItems={"center"}
    >
      <Box w={"100%"} px={{ base: "0rem", md: "7rem" }} pt="2rem" pb="4rem">
        <VStack w="100%" h="100%" alignItems={"flex-start"} spacing="5rem">
          <Box position="relative" w="100%">
            <Text
              variant={"header"}
              fontWeight="bold"
              color="#122c6c"
              position={"relative"}
              _before={{
                content: "''",
                position: "absolute",
                left: "0",
                bottom: "0",
                top: "calc(100% + 10px)",
                height: "0.5rem",
                width: "10rem",
                backgroundColor: "rgb(246, 142, 19) !important",
              }}
            >
              About us
            </Text>
          </Box>
          <HStack
            w="100%"
            alignItems={"flex-start"}
            spacing="2rem"
            minH="25rem"
          >
            <VStack
              h="100%"
              w="100%"
              pr="2rem"
              overflow={"hidden"}
              flex={1}
              spacing="2rem"
            >
              <Text
                as="span"
                variant={"description"}
                color="black"
                textAlign={"justify"}
              >
                <Text fontWeight={"bold"} as="span" color="var(--orange-color)">
                  H&Q Advisory Limited
                </Text>{" "}
                was founded by a number of rich experienced business advisors on
                corporate finance, auditing and management; providing strategic
                corporate finance, financial advisory services, M&A, Due
                diligence, accounting and tax advisory to all economic
                organizations in various sectors on a national scale.
              </Text>
              <Text
                as="span"
                variant={"description"}
                color="black"
                textAlign={"justify"}
                flex={1}
              >
                We are an{" "}
                <Text fontWeight={"bold"} as="span" color="var(--orange-color)">
                  Independent Member of Morison Global
                </Text>
                , a leading international association of high-quality
                professional services firms. Underpinned by its values, Morison
                Global collaborates to serve the cross-border accounting,
                auditing, tax and consulting needs of its members’ clients.
              </Text>
              <Button
                rightIcon={<ArrowForwardIcon />}
                variant="outline"
                borderRadius={0}
                h="5.5rem"
                w="15rem"
                borderColor={"var(--blue-color)"}
                color="var(--blue-color)"
                fontSize={"1.6rem"}
                fontWeight="medium"
                bg="white"
                fontStyle={"italic"}
                _hover={{
                  bg: "var(--blue-color)",
                  color: "white",
                }}
                alignSelf="flex-end"
                justifySelf={"flex-end"}
                onClick={() => {
                  window.open("https://www.morisonglobal.com/", "_blank");
                }}
              >
                For more
              </Button>
            </VStack>
            {/* TBD 
            <Box
              backgroundImage="url('/img/banner.jpeg')"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              backgroundAttachment="scroll"
              backgroundPosition="50% 50%"
              h="100%"
              w="50%"
              position={"relative"}
            ></Box> */}
          </HStack>
          <Box position={"relative"} w="100%" mt="2rem">
            <Box
              w="100%"
              h="1rem"
              _before={{
                content: "''",
                display: "block",
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "4rem",
                backgroundImage: "url('/img/dots-bg-repeat.png')",
                backgroundRepeat: "repeat-x",
                backgroundSize: "contain",
              }}
            ></Box>
          </Box>

          <HStack w="100%" alignItems={"flex-start"} spacing="2rem">
            <VStack
              h="100%"
              w="100%"
              overflow={"hidden"}
              flex={1}
              spacing="2rem"
            >
              <Text
                as="span"
                variant={"description"}
                color="black"
                textAlign={"justify"}
                flex={1}
              >
                We were accredited to be a{" "}
                <Text fontWeight={"bold"} as="span" color="var(--orange-color)">
                  Strategic Alliance of Avista Advisory Group (“Avista
                  Advisory”)
                </Text>{" "}
                in Vietnam. Avista Advisory is a boutique Investment Banking
                advisory firm, which over the last two decades has established
                itself as a well-entrenched and proven franchise across India &
                Southeast Asia. Avista Advisory has successfully combined their
                deep domain expertise, understanding of business and cultural
                dynamics and their robust network of financial sponsors and
                corporates to execute even the most complex M&A, Restructuring
                and Private Finance transactions.
              </Text>

              <Button
                rightIcon={<ArrowForwardIcon />}
                variant="outline"
                borderRadius={0}
                h="5.5rem"
                w="15rem"
                borderColor={"var(--blue-color)"}
                color="var(--blue-color)"
                fontSize={"1.6rem"}
                fontWeight="medium"
                bg="white"
                fontStyle={"italic"}
                _hover={{
                  bg: "var(--blue-color)",
                  color: "white",
                }}
                alignSelf="flex-end"
                justifySelf={"flex-end"}
                onClick={() => {
                  window.open("https://avistaadvisory.com/", "_blank");
                }}
              >
                For more
              </Button>
            </VStack>
          </HStack>
        </VStack>
      </Box>
    </Flex>
  );
};

export default AboutUsPage;
