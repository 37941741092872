import {
  Box,
  BoxProps,
  Center,
  CenterProps,
  Image,
  ImageProps,
} from "@chakra-ui/react";

const Partner = ({
  src,
  isSelect,
  imgProps,
  partnerSite,
  containerProps,
  ...rest
}: {
  src: string;
  imgProps?: ImageProps;
  isSelect?: boolean;
  partnerSite?: string;
  containerProps?: CenterProps;
} & BoxProps) => {
  const onNavigate = () => {
    if (partnerSite) {
      window.open(partnerSite, "_blank");
    }
  };
  return (
    <Center
      w="100%"
      h="100%"
      _hover={{
        boxShadow: "0 0 15px rgba(8,40,85,.4)",
        transform: "scale(1.05)",
      }}
      padding="1rem"
      cursor="pointer"
      transition="transform .2s"
      {...containerProps}
      onClick={onNavigate}
    >
      <Box
        w={"60%"}
        h="100%"
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        {...rest}
      >
        <Image
          src={src}
          // zIndex={1}
          w="100%"
          fit={"cover"}
          {...imgProps}
          // filter={
          //   "brightness( 200% ) contrast( 80% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg )"
          // }
          // _hover={{
          //   filter: "unset",
          // }}
        />
      </Box>
    </Center>
  );
};

export default Partner;
